import React from "react";
import logo from "../Images/logo.png";
import { MDBCol, MDBRow } from "mdbreact";

function Header() {
  return (
    <>
      <MDBRow center className="pb-5">
        <img src={logo} alt="logo Info Public" height={80} />
        <br />
      </MDBRow>
      <MDBRow center>
        <MDBCol size="9">
          <p>
            Phasellus lacus quam, tincidunt at elit a, laoreet accumsan ex. Nunc
            eu elementum nunc. Nulla facilisi. Curabitur est felis, cursus id
            felis vel Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </MDBCol>
      </MDBRow>
    </>
  );
}

export default Header;
