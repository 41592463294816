import React, { useRef, useState } from 'react';
import { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';

registerLocale('fr', fr); // register it with the name you want

function FicheVictime(props) {
  const {
    title,
    initialData,
    register,
    errors,
    required,
    maxLength,
    errorMessage,
    watch,
    control,
    getValues,
    setValue
  } = props;

  const [adresseFrVic, setAdresseFrVic] = useState(false);

  const mailVictime = useRef({});

  mailVictime.current = watch('victime.personne.mail', '');

  return (
    <div key='FicheVictime'>
      <div className='row' size='auto'>
        <p style={{ color: '#000094' }} className='h3 m-4'>
          {title}
        </p>
      </div>
      <div className='row m-3'>
        <div className='col'>
          <div className='form-check form-check-inline'>
            <label className='form-check-label grey-text'>Civilité*</label>
          </div>

          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='monsieurVictime'
              value='Monsieur'
              {...register('victime.personne.civilite', { required: true })}
            />
            <label className='form-check-label' htmlFor='monsieurVictime'>
              Monsieur
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='madameVictime'
              value='Madame'
              {...register('victime.personne.civilite', { required: true })}
            />
            <label className='form-check-label' htmlFor='madameVictime'>
              Madame
            </label>
          </div>
          {errors.victime?.personne?.civilite &&
            errors.victime?.personne?.civilite?.type === 'required' &&
            errorMessage(required)}
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='nomNaissanceVictime' className='form-label grey-text'>
            Nom de naissance*
          </label>
          <input
            type='text'
            className='form-control'
            id='nomNaissanceVictime'
            {...register('victime.personne.nomNaissance', {
              validate: {
                required: (value) => {
                  if (getValues('victime.personne.nomUsage') === '' && value === '') {
                    return false;
                  }
                }
              }
            })}
          />
          {errors.victime?.personne?.nomNaissance &&
            errors?.victime?.personne?.nomNaissance?.type === 'required' &&
            errorMessage(required)}
          {errors.victime?.personne?.nomNaissance &&
            errors.victime?.personne?.nomNaissance?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>

        <div className='col-md-3'>
          <label htmlFor='nomUsageVictime' className='form-label grey-text'>
            Nom d'usage**
          </label>
          <input
            type='text'
            className='form-control'
            id='nomUsageVictime'
            {...register('victime.personne.nomUsage', {
              validate: {
                required: (value) => {
                  if (getValues('victime.personne.nomNaissance') === '' && value === '') {
                    return false;
                  }
                }
              }
            })}
          />
          {errors.victime?.personne?.nomUsage &&
            errors?.victime?.personne?.nomUsage?.type === 'required' &&
            errorMessage(required)}
          {errors.victime?.personne?.nomUsage &&
            errors.victime?.personne?.nomUsage?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>

        <div className='col-md-3'>
          <label htmlFor='prenomVictime' className='form-label grey-text'>
            Prénom*
          </label>
          <input
            type='text'
            className='form-control'
            id='prenomVictime'
            {...register('victime.personne.prenom', { required: true, maxLength: 30 })}
          />

          {errors.victime?.personne?.prenom &&
            errors?.victime?.personne?.prenom?.type === 'required' &&
            errorMessage(required)}
          {errors.victime?.personne?.prenom &&
            errors.victime?.personne?.prenom?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>

        <div className='col-md-3'>
          <label htmlFor='dateNaissanceVictime' className='form-label grey-text'>
            Date de naissance
          </label>
          <Controller
            control={control}
            name='victime.personne.dateNaissance'
            render={({ field: { onChange, ref, ...rest } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <KeyboardDatePicker
                  autoOk
                  minDate={new Date('01/01/1900')}
                  minDateMessage='Veuillez saisir une date supérieur au 31/12/1899'
                  maxDate={new Date('01/01/3000')}
                  maxDateMessage='Veuillez saisir une date inférieur au 02/01/3000'
                  invalidDateMessage='Date non valide'
                  name='victime.personne.dateNaissance'
                  format='dd/MM/yyyy'
                  variant='inline'
                  inputVariant='outlined'
                  placeholder='JJ/MM/AAAA'
                  size='small'
                  className='form-control'
                  onChange={(value) =>
                    onChange({
                      target: {
                        id: 'victime.personne.dateNaissance',
                        name: 'victime.personne.dateNaissance',
                        value: moment(value).format('YYYY-MM-DD') ? moment(value).format('YYYY-MM-DD') : ''
                      }
                    })
                  }
                  {...rest}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='nationaliteVictime' className='form-label grey-text'>
            Nationalité
          </label>
          <select id='nationaliteVictime' className='form-control' {...register('victime.personne.nationalite')}>
            <option value=''></option>
            {initialData.listNationalite.map((nationalite, index) => (
              <option key={index} value={nationalite.nationalite}>
                {nationalite.nationalite}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-3'>
          <label htmlFor='nationaliteDeuxVictime' className='form-label grey-text'>
            Nationalité secondaire
          </label>
          <select
            id='nationaliteDeuxVictime'
            className='form-control'
            {...register('victime.personne.nationaliteDeux')}
          >
            <option value=''></option>
            {initialData.listNationalite.map((nationalite, index) => (
              <option key={index} value={nationalite.nationalite}>
                {nationalite.nationalite}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-3'>
          <label htmlFor='telephoneVictime' className='form-label grey-text'>
            Téléphone
          </label>
          <input
            type='tel'
            className='form-control'
            id='telephoneVictime'
            pattern='^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$'
            {...register('victime.personne.telephone', { maxLength: 12 })}
          />
          {errors.victime?.personne?.telephone &&
            errors.victime?.personne?.telephone?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>
      </div>

      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='mailVictime' className='form-label grey-text'>
            Courriel
          </label>
          <input type='email' className='form-control' id='mailVictime' {...register('victime.personne.mail')} />
        </div>
      </div>

      <div className='row m-3'>
        <div className='col-md-12'>
          <label htmlFor='texteLibreVictime' className='form-label grey-text'>
            Informations supplémentaires
          </label>
          <textarea
            className='form-control'
            aria-label='With textarea'
            id='texteLibreVictime'
            {...register('victime.personne.texteLibre')}
          />
        </div>
      </div>
      <br />
      <div className='row m-3'>
        <div className='form-check form-check-inline'>
          <label className='form-check-label grey-text'>Adresse Française ?</label>
        </div>

        <div className='form-check form-check-inline'>
          <Controller
            control={control}
            name='victime.personne.adresse.adresseEtrangere'
            render={(props) => (
              <input
                className='form-check-input'
                type='radio'
                {...props}
                value={false}
                checked={!adresseFrVic}
                onChange={() => {
                  setValue('victime.personne.adresse.adresseEtrangere', false);
                  setAdresseFrVic(false);
                }}
              />
            )}
          />
          <label className='form-check-label' htmlFor='adresseFrVictimeOui'>
            Oui
          </label>
        </div>
        <div className='form-check form-check-inline'>
          <Controller
            control={control}
            name='victime.personne.adresse.adresseEtrangere'
            render={(props) => (
              <input
                className='form-check-input'
                type='radio'
                value={true}
                {...props}
                checked={adresseFrVic}
                onChange={() => {
                  setValue('victime.personne.adresse.adresseEtrangere', true);
                  setAdresseFrVic(true);
                }}
              />
            )}
          />
          <label className='form-check-label' htmlFor='adresseFrVictimeNon'>
            Non
          </label>
        </div>
      </div>

      {!adresseFrVic ? (
        <>
          <div className='row m-3'>
            <div className='col-md-1'>
              <label htmlFor='numeroVictime' className='form-label grey-text'>
                Numéro
              </label>
              <input
                type='text'
                className='form-control'
                id='numeroVictime'
                {...register('victime.personne.adresse.numero')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='complement' className='form-label grey-text'>
                Complément
              </label>
              <select id='complement' className='form-control' {...register('victime.personne.adresse.complement')}>
                <option value=''></option>
                <option value='bis'>bis</option>
                <option value='ter'>ter</option>
                <option value='quater'>quater</option>
              </select>
            </div>

            <div className='col-md-2'>
              <label htmlFor='voieVictime' className='form-label grey-text'>
                Voie
              </label>
              <select id='voieVictime' className='form-control' {...register('victime.personne.adresse.voie')}>
                <option value=''></option>
                {initialData.listVoie.map((voie, index) => (
                  <option key={index} value={voie.typeVoie}>
                    {voie.typeVoie}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-6'>
              <label htmlFor='nomVoieVictime' className='form-label grey-text'>
                Nom de la voie
              </label>
              <input
                type='text'
                className='form-control'
                id='nomVoieVictime'
                {...register('victime.personne.adresse.nomVoie')}
              />
            </div>
          </div>

          <div className='row m-3'>
            <div className='col-md-4'>
              <label htmlFor='residenceVictime' className='form-label grey-text'>
                Résidence
              </label>
              <input
                type='text'
                className='form-control'
                id='residenceVictime'
                {...register('victime.personne.adresse.immeuble')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='immeubleVictime' className='form-label grey-text'>
                Immeuble
              </label>
              <input
                type='text'
                className='form-control'
                id='immeubleVictime'
                {...register('victime.personne.adresse.residence')}
              />
            </div>

            <div className='col-md-1'>
              <label htmlFor='etageVictime' className='form-label grey-text'>
                Etage
              </label>
              <input
                type='text'
                className='form-control'
                id='etageVictime'
                {...register('victime.personne.adresse.etage')}
              />
            </div>

            <div className='col-md-1'>
              <label htmlFor='appartementVictime' className='form-label grey-text'>
                Appartement
              </label>
              <input
                type='text'
                className='form-control'
                id='appartementVictime'
                {...register('victime.personne.adresse.appartement')}
              />
            </div>
          </div>

          <div className='row m-3'>
            <div className='col-md-2'>
              <label htmlFor='codePostalVictime' className='form-label grey-text'>
                Code postal
              </label>
              <input
                type='text'
                className='form-control'
                id='codePostalVictime'
                {...register('victime.personne.adresse.codePostal')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='villeVictime' className='form-label grey-text'>
                Ville
              </label>
              <input
                type='text'
                className='form-control'
                id='villeVictime'
                {...register('victime.personne.adresse.ville')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='cedexVictime' className='form-label grey-text'>
                Cedex
              </label>
              <input
                type='text'
                className='form-control'
                id='cedexVictime'
                {...register('victime.personne.adresse.cedex')}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row m-3'>
            <div className='col-md-6'>
              <label htmlFor='codePostalVictime' className='form-label grey-text'>
                Adresse
              </label>
              <input
                type='text'
                className='form-control'
                id='adresseVictime'
                {...register('victime.personne.adresse.rueEtrangere')}
              />
            </div>
          </div>
          <div className='row m-3'>
            <div className='col-md-2'>
              <label htmlFor='codePostalVictime' className='form-label grey-text'>
                Code postal
              </label>
              <input
                type='text'
                className='form-control'
                id='codePostalVictime'
                {...register('victime.personne.adresse.codePostal')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='villeVictime' className='form-label grey-text'>
                Ville
              </label>
              <input
                type='text'
                className='form-control'
                id='villeVictime'
                {...register('victime.personne.adresse.ville')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='voieVictime' className='form-label grey-text'>
                Pays
              </label>
              <select id='paysVictime' className='form-control' {...register('victime.personne.adresse.paysEtranger')}>
                <option value=''></option>
                {initialData.listPays.map((pays, index) => (
                  <option key={index} value={pays.nom_fr_fr}>
                    {pays.nom_fr_fr}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
      <br />
    </div>
  );
}

export default FicheVictime;
