import React, { useEffect, useState } from 'react';
import FicheAppelant from './FicheAppelant';
import FicheVictime from './FicheVictime';
import Axios from 'axios';
import { useForm } from 'react-hook-form';
import { MDBBtn, MDBCol, MDBRow } from 'mdbreact';
import Event from './Event';
import { Modal } from './Generic/Modal';

const Body = () => {
  const [initialData, setInitialData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const defaultValues = { 'appelant.personne.dateNaissance': null, 'victime.personne.dateNaissance': null };
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
    control,
    getValues,
    setValue
  } = useForm({ defaultValues });
  const [data, setData] = useState({});

  // Messages
  const required = 'Veuillez renseigner ce champ';
  const maxLength = 'Votre entrée dépasse la longueur maximale';

  const [displayConfirmModal, setDisplayConfirmModal] = useState(false);
  const [displayValidationModal, setDisplayValidationModal] = useState(false);
  const [displayErrorModal, setDisplayErrorModal] = useState(false);
  const [validationCode, setValidationCode] = useState('');

  // Error Component
  const errorMessage = (error) => {
    return <div className='invalid-feedback'>{error}</div>;
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_BACKEND_URL}/formulaire`).then((response) => {
      setInitialData(response.data);
      setLoaded(true);
    });
  }, []);

  const onSubmit = (data) => {
    setData(data);
    setValidationCode('');
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/email`, data).then((response) => {
      setDisplayConfirmModal(!displayConfirmModal);
    });
  };

  const confirmCode = () => {
    data.validationCode = validationCode;
    setDisplayConfirmModal(!displayConfirmModal);
    Axios.post(`${process.env.REACT_APP_BACKEND_URL}/formulaire`, data).then((response) => {
      if (response.data) {
        reset();
        setDisplayValidationModal(!displayValidationModal);
      } else {
        setDisplayErrorModal(!displayErrorModal);
      }
    });
  };

  return (
    loaded && (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Event
            title={'Événement'}
            initialData={initialData}
            register={register}
            errors={errors}
            required={required}
            errorMessage={errorMessage}
          />

          <FicheAppelant
            title={'Vos Informations Personnelles'}
            initialData={initialData}
            register={register}
            errors={errors}
            required={required}
            maxLength={maxLength}
            errorMessage={errorMessage}
            watch={watch}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />

          <FicheVictime
            key='FicheVictime'
            title={'Les Informations concernant la personne que vous recherchez'}
            initialData={initialData}
            register={register}
            errors={errors}
            required={required}
            maxLength={maxLength}
            errorMessage={errorMessage}
            watch={watch}
            control={control}
            getValues={getValues}
            setValue={setValue}
          />

          <MDBRow end className='m-2'>
            <MDBBtn
              size='sm'
              color='indigo'
              outline
              type='button'
              style={{ borderRadius: '20px' }}
              onClick={() => reset()}
            >
              Annuler
            </MDBBtn>
            <MDBBtn size='sm' className='mr-4' color='indigo' type='submit' style={{ borderRadius: '20px' }}>
              Valider
            </MDBBtn>
          </MDBRow>
          <br />
          <MDBRow center>
            <MDBCol size='9'>
              <p style={{ fontSize: 'small' }}>
                Données juridiques Phasellus lacus quam, tincidunt at elit a, laoreet accumsan ex. Nunc eu elementum
                nunc. Nulla facilisi. Curabitur est felis, cursus id felis vel Lorem ipsum dolor sit amet, consectetur
                adipiscing elit.
              </p>
            </MDBCol>
          </MDBRow>
        </form>
        {/*Send code modal*/}
        <Modal
          modalBody={[
            <input
              key='text-send-code'
              type='text'
              className='form-control'
              placeholder='Code de validation'
              value={validationCode}
              onChange={(event) => setValidationCode(event.target.value)}
            />
          ]}
          size='lg'
          colorHeader='indigo-text'
          modalHeader='Code de confirmation'
          okText='Valider'
          okIcon='check'
          okColor='indigo'
          koIcon='times'
          koText='Annuler'
          koColor='light'
          okHandler={() => confirmCode()}
          koHandler={() => setDisplayConfirmModal(!displayConfirmModal)}
          toggleModal={() => setDisplayConfirmModal(!displayConfirmModal)}
          isModalOpen={displayConfirmModal}
        />
        {/*Code Validé*/}
        <Modal
          modalBody={[<label key='text-valide-code'>Votre demande à été envoyé</label>]}
          size='lg'
          colorHeader='indigo-text'
          modalHeader='Demande envoyé'
          okText='Fermer'
          okIcon='check'
          okColor='indigo'
          koColor='light'
          okHandler={() => setDisplayValidationModal(!displayValidationModal)}
          toggleModal={() => setDisplayValidationModal(!displayValidationModal)}
          isModalOpen={displayValidationModal}
        />
        {/*Code erreur*/}
        <Modal
          modalBody={[<label key='text-error-code'>Le code que vous avez saisi n'est pas valide</label>]}
          size='lg'
          colorHeader='indigo-text'
          modalHeader='Code saisi incorrect'
          okText='Réessayer'
          okIcon='check'
          okColor='indigo'
          koIcon='times'
          koText='Annuler'
          koColor='light'
          okHandler={() => {
            setDisplayErrorModal(!displayErrorModal);
            setDisplayConfirmModal(!displayConfirmModal);
          }}
          koHandler={() => setDisplayErrorModal(!displayErrorModal)}
          toggleModal={() => setDisplayErrorModal(!displayErrorModal)}
          isModalOpen={displayErrorModal}
        />
      </>
    )
  );
};
export default Body;
