import React from 'react';

function Event(props) {
  const { title, initialData, register, errors, required, errorMessage } = props;

  return (
    <div key='Event'>
      <div className='row' size='auto'>
        <div className='col'>
          <p style={{ color: '#000094' }} className='h3 m-4 '>
            {title}
          </p>
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-6'>
          <label htmlFor='event' className='form-label grey-text'>
            Événement*
          </label>
          <select className='form-control' {...register('idEvent', { required: true })} id='event'>
            <option value=''></option>
            {initialData.listEvent.map((event, index) => (
              <option key={index} value={event.idEvent}>
                {event.intitule}
              </option>
            ))}
          </select>
          {errors.idEvent && errors.idEvent.type === 'required' && errorMessage(required)}
        </div>
      </div>
    </div>
  );
}

export default Event;
