import React, { Component } from 'react';
import { MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';

export class Modal extends Component {
  state = {
    isSubmitting: false
  };

  static defaultProps = {
    backdrop: true
  };

  render() {
    return (
      <MDBModal
        isOpen={this.props.isModalOpen}
        toggle={this.props.toggleModal}
        size={this.props.size}
        backdrop={this.props.backdrop}
      >
        <MDBModalHeader className={this.props.colorHeader}>{this.props.modalHeader}</MDBModalHeader>
        <MDBModalBody>{this.props.modalBody}</MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            color={this.props.okColor}
            onClick={() => {
              this.props.isSubmitModal && this.setState({ isSubmitting: true });
              this.props.okHandler();
            }}
            disabled={this.state.isSubmitting}
          >
            {this.props.okIcon && <MDBIcon icon={this.props.okIcon} className='mr-2' />}
            {this.props.okText}
          </MDBBtn>
          {this.props.koText && (
            <MDBBtn color={this.props.koColor} onClick={this.props.koHandler}>
              {this.props.koIcon && <MDBIcon icon={this.props.koIcon} className='mr-2' />}
              {this.props.koText}
            </MDBBtn>
          )}
        </MDBModalFooter>
      </MDBModal>
    );
  }
}
