import React, { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import { MDBCol, MDBRow } from 'mdbreact';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment'; // the locale you want
registerLocale('fr', fr); // register it with the name you want

function FicheAppelant(props) {
  const {
    title,
    initialData,
    register,
    errors,
    required,
    maxLength,
    errorMessage,
    watch,
    control,
    getValues,
    setValue
  } = props;

  const [adresseFrApp, setAdresseFrApp] = useState(false);
  const mailAppelant = useRef({});
  mailAppelant.current = watch('appelant.personne.mail', '');

  return (
    <div key='FicheAppelant'>
      <MDBRow between>
        <MDBCol size='auto' middle>
          <p style={{ color: '#000094' }} className='h3 m-4'>
            {title}
          </p>
        </MDBCol>
        <MDBCol size='auto' middle>
          <p
            style={{
              color: 'grey',
              fontStyle: 'italic',
              fontSize: 'small'
            }}
            className='h2 m-4'
          >
            * Champ obligatoire
          </p>
        </MDBCol>
      </MDBRow>
      <div className='row m-3'>
        <div className='col'>
          <div className='form-check form-check-inline'>
            <label className='form-check-label grey-text'>Civilité*</label>
          </div>

          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='monsieurAppelant'
              value='Monsieur'
              {...register('appelant.personne.civilite', { required: true })}
            />
            <label className='form-check-label' htmlFor='monsieurAppelant'>
              Monsieur
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='madameAppelant'
              value='Madame'
              {...register('appelant.personne.civilite', { required: true })}
            />
            <label className='form-check-label' htmlFor='madameAppelant'>
              Madame
            </label>
          </div>
          {errors.appelant?.personne?.civilite &&
            errors.appelant?.personne?.civilite?.type === 'required' &&
            errorMessage(required)}
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='nomNaissanceAppelant' className='form-label grey-text'>
            Nom de naissance*
          </label>
          <input
            type='text'
            className='form-control'
            id='nomNaissanceAppelant'
            {...register('appelant.personne.nomNaissance', {
              validate: {
                required: (value) => {
                  if (getValues('appelant.personne.nomUsage') === '' && value === '') {
                    return false;
                  }
                }
              }
            })}
          />
          {errors.appelant?.personne?.nomNaissance &&
            errors?.appelant?.personne?.nomNaissance?.type === 'required' &&
            errorMessage(required)}
          {errors.appelant?.personne?.nomNaissance &&
            errors.appelant?.personne?.nomNaissance?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>
        <div className='col-md-3'>
          <label htmlFor='nomUsageAppelant' className='form-label grey-text'>
            Nom d'usage**
          </label>
          <input
            type='text'
            className='form-control'
            id='nomUsageAppelant'
            {...register('appelant.personne.nomUsage', {
              validate: {
                required: (value) => {
                  if (getValues('appelant.personne.nomNaissance') === '' && value === '') {
                    return false;
                  }
                }
              }
            })}
          />
          {errors.appelant?.personne?.nomUsage &&
            errors?.appelant?.personne?.nomUsage?.type === 'required' &&
            errorMessage(required)}
          {errors.appelant?.personne?.nomUsage &&
            errors.appelant?.personne?.nomUsage?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>

        <div className='col-md-3'>
          <label htmlFor='prenomAppelant' className='form-label grey-text'>
            Prénom*
          </label>
          <input
            type='text'
            className='form-control'
            id='prenomAppelant'
            {...register('appelant.personne.prenom', { required: true, maxLength: 30 })}
          />

          {errors.appelant?.personne?.prenom &&
            errors?.appelant?.personne?.prenom?.type === 'required' &&
            errorMessage(required)}
          {errors.appelant?.personne?.prenom &&
            errors.appelant?.personne?.prenom?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>

        <div className='col-md-3'>
          <label htmlFor='dateNaissanceAppelant' className='form-label grey-text'>
            Date de naissance
          </label>

          <Controller
            control={control}
            name='appelant.personne.dateNaissance'
            render={({ field: { onChange, ref, ...rest } }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                <KeyboardDatePicker
                  autoOk
                  minDate={new Date('01/01/1900')}
                  minDateMessage='Veuillez saisir une date supérieur au 31/12/1899'
                  maxDate={new Date('01/01/3000')}
                  maxDateMessage='Veuillez saisir une date inférieur au 02/01/3000'
                  invalidDateMessage='Date non valide'
                  name='appelant.personne.dateNaissance'
                  format='dd/MM/yyyy'
                  variant='inline'
                  inputVariant='outlined'
                  placeholder='JJ/MM/AAAA'
                  size='small'
                  className='form-control'
                  onChange={(value) =>
                    onChange({
                      target: {
                        value: moment(value).format('YYYY-MM-DD') ? moment(value).format('YYYY-MM-DD') : ''
                      }
                    })
                  }
                  {...rest}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='nationaliteAppelant' className='form-label grey-text'>
            Nationalité
          </label>
          <select id='nationaliteAppelant' className='form-control' {...register('appelant.personne.nationalite')}>
            <option value=''></option>
            {initialData.listNationalite.map((nationalite, index) => (
              <option key={index} value={nationalite.nationalite}>
                {nationalite.nationalite}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-3'>
          <label htmlFor='nationaliteDeuxAppelant' className='form-label grey-text'>
            Nationalité secondaire
          </label>
          <select
            id='nationaliteDeuxAppelant'
            className='form-control'
            {...register('appelant.personne.nationaliteDeux')}
          >
            <option value=''></option>
            {initialData.listNationalite.map((nationalite, index) => (
              <option key={index} value={nationalite.nationalite}>
                {nationalite.nationalite}
              </option>
            ))}
          </select>
        </div>

        <div className='col-md-3'>
          <label htmlFor='telephoneAppelant' className='form-label grey-text'>
            Téléphone*
          </label>
          <input
            type='tel'
            className='form-control'
            id='telephoneAppelant'
            pattern='^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$'
            {...register('appelant.personne.telephone', { required: true, maxLength: 12 })}
          />
          {errors.appelant?.personne?.telephone &&
            errors.appelant?.personne?.telephone?.type === 'required' &&
            errorMessage(required)}
          {errors.appelant?.personne?.telephone &&
            errors.appelant?.personne?.telephone?.type === 'maxLength' &&
            errorMessage(maxLength)}
        </div>
      </div>

      <div className='row m-3'>
        <div className='col-md-3'>
          <label htmlFor='mailAppelant' className='form-label grey-text'>
            Courriel*
          </label>
          <input
            type='email'
            className='form-control'
            id='mailAppelant'
            {...register('appelant.personne.mail', { required: true, pattern: /^\S+@\S+$/i })}
          />
          {errors.appelant?.personne?.mail &&
            errors.appelant?.personne?.mail?.type === 'required' &&
            errorMessage(required)}
        </div>
        <div className='col-md-3'>
          <label htmlFor='confCourriel' className='form-label grey-text'>
            Confirmation du courriel*
          </label>
          <input
            type='email'
            className='form-control'
            id='confCourriel'
            {...register('appelant.personne.mail_repeat', {
              required: true,
              pattern: /^\S+@\S+$/i,
              validate: (value) => value === mailAppelant.current
            })}
          />
          {errors.appelant?.personne?.mail_repeat &&
            errors.appelant?.personne?.mail_repeat?.type === 'required' &&
            errorMessage(required)}
          {errors.appelant?.personne?.mail_repeat &&
            errors.appelant?.personne?.mail_repeat?.type === 'validate' &&
            errorMessage('Les courriels ne correspondent pas')}
        </div>
      </div>
      <div className='row m-3'>
        <div className='col-md-12'>
          <label htmlFor='texteLibreAppelant' className='form-label grey-text'>
            Informations supplémentaires
          </label>
          <textarea
            className='form-control'
            aria-label='With textarea'
            id='texteLibreAppelant'
            {...register('appelant.personne.texteLibre')}
          />
        </div>
      </div>
      <br />
      <div className='row m-3'>
        <div className='col'>
          <div className='form-check form-check-inline'>
            <label className='form-check-label grey-text'>Adresse Française ?</label>
          </div>

          <div className='form-check form-check-inline'>
            <Controller
              control={control}
              name='appelant.personne.adresse.adresseEtrangere'
              render={(props) => (
                <input
                  className='form-check-input'
                  type='radio'
                  {...props}
                  value={false}
                  checked={!adresseFrApp}
                  onChange={() => {
                    setValue('appelant.personne.adresse.adresseEtrangere', false);
                    setAdresseFrApp(false);
                  }}
                />
              )}
            />
            <label className='form-check-label' htmlFor='adresseFrAppelantOui'>
              Oui
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <Controller
              control={control}
              name='appelant.personne.adresse.adresseEtrangere'
              render={(props) => (
                <input
                  className='form-check-input'
                  type='radio'
                  value={true}
                  {...props}
                  checked={adresseFrApp}
                  onChange={() => {
                    setValue('appelant.personne.adresse.adresseEtrangere', true);
                    setAdresseFrApp(true);
                  }}
                />
              )}
            />
            <label className='form-check-label' htmlFor='adresseFrAppelantNon'>
              Non
            </label>
          </div>
        </div>
      </div>

      {!adresseFrApp ? (
        <>
          <div className='row m-3'>
            <div className='col-md-1'>
              <label htmlFor='numeroAppelant' className='form-label grey-text'>
                Numéro
              </label>
              <input
                type='text'
                className='form-control'
                id='numeroAppelant'
                {...register('appelant.personne.adresse.numero')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='complement' className='form-label grey-text'>
                Complément
              </label>
              <select id='complement' className='form-control' {...register('appelant.personne.adresse.complement')}>
                <option value=''></option>
                <option value='bis'>bis</option>
                <option value='ter'>ter</option>
                <option value='quater'>quater</option>
              </select>
            </div>

            <div className='col-md-2'>
              <label htmlFor='voieAppelant' className='form-label grey-text'>
                Voie
              </label>
              <select id='voieAppelant' className='form-control' {...register('appelant.personne.adresse.voie')}>
                <option value=''></option>
                {initialData.listVoie.map((voie, index) => (
                  <option key={index} value={voie.typeVoie}>
                    {voie.typeVoie}
                  </option>
                ))}
              </select>
            </div>

            <div className='col-md-6'>
              <label htmlFor='nomVoieAppelant' className='form-label grey-text'>
                Nom de la voie
              </label>
              <input
                type='text'
                className='form-control'
                id='nomVoieAppelant'
                {...register('appelant.personne.adresse.nomVoie')}
              />
            </div>
          </div>

          <div className='row m-3'>
            <div className='col-md-4'>
              <label htmlFor='residenceAppelant' className='form-label grey-text'>
                Résidence
              </label>
              <input
                type='text'
                className='form-control'
                id='residenceAppelant'
                {...register('appelant.personne.adresse.immeuble')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='immeubleAppelant' className='form-label grey-text'>
                Immeuble
              </label>
              <input
                type='text'
                className='form-control'
                id='immeubleAppelant'
                {...register('appelant.personne.adresse.residence')}
              />
            </div>

            <div className='col-md-1'>
              <label htmlFor='etageAppelant' className='form-label grey-text'>
                Etage
              </label>
              <input
                type='text'
                className='form-control'
                id='etageAppelant'
                {...register('appelant.personne.adresse.etage')}
              />
            </div>

            <div className='col-md-1'>
              <label htmlFor='appartementAppelant' className='form-label grey-text'>
                Appartement
              </label>
              <input
                type='text'
                className='form-control'
                id='appartementAppelant'
                {...register('appelant.personne.adresse.appartement')}
              />
            </div>
          </div>

          <div className='row m-3'>
            <div className='col-md-2'>
              <label htmlFor='codePostalAppelant' className='form-label grey-text'>
                Code postal
              </label>
              <input
                type='text'
                className='form-control'
                id='codePostalAppelant'
                {...register('appelant.personne.adresse.codePostal')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='villeAppelant' className='form-label grey-text'>
                Ville
              </label>
              <input
                type='text'
                className='form-control'
                id='villeAppelant'
                {...register('appelant.personne.adresse.ville')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='cedexAppelant' className='form-label grey-text'>
                Cedex
              </label>
              <input
                type='text'
                className='form-control'
                id='cedexAppelant'
                {...register('appelant.personne.adresse.cedex')}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row m-3'>
            <div className='col-md-6'>
              <label htmlFor='codePostalAppelant' className='form-label grey-text'>
                Adresse
              </label>
              <input
                type='text'
                className='form-control'
                id='adresseAppelant'
                {...register('appelant.personne.adresse.rueEtrangere')}
              />
            </div>
          </div>
          <div className='row m-3'>
            <div className='col-md-2'>
              <label htmlFor='codePostalAppelant' className='form-label grey-text'>
                Code postal
              </label>
              <input
                type='text'
                className='form-control'
                id='codePostalAppelant'
                {...register('appelant.personne.adresse.codePostal')}
              />
            </div>

            <div className='col-md-4'>
              <label htmlFor='villeAppelant' className='form-label grey-text'>
                Ville
              </label>
              <input
                type='text'
                className='form-control'
                id='villeAppelant'
                {...register('appelant.personne.adresse.ville')}
              />
            </div>

            <div className='col-md-2'>
              <label htmlFor='voieAppelant' className='form-label grey-text'>
                Pays
              </label>
              <select
                id='paysAppelant'
                className='form-control'
                {...register('appelant.personne.adresse.paysEtranger')}
              >
                <option value=''></option>
                {initialData.listPays.map((pays, index) => (
                  <option key={index} value={pays.nom_fr_fr}>
                    {pays.nom_fr_fr}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )}
      <br />
      <div className='row m-3'>
        <div className='col-md-6'>
          <label htmlFor='lienVictime' className='form-label grey-text'>
            Lien avec la personne recherchée
          </label>
          <select id='lienVictime' className='form-control' {...register('lienAppelantVictime')}>
            <option value=''></option>
            {initialData.listLienAppelantVictime.map((lienAppelantVictime, index) => (
              <option key={index} value={lienAppelantVictime.lien}>
                {lienAppelantVictime.lien}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default FicheAppelant;
