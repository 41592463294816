import './App.css';
import Header from './Components/Header';
import React from 'react';
import Body from './Components/Body';
import { MDBCard, MDBCardBody, MDBContainer } from 'mdbreact';

function App() {
  return (
    <MDBContainer style={{ paddingTop: '6rem' }} className='pb-5'>
      <MDBCard>
        <MDBCardBody>
          <Header />
          <Body />
        </MDBCardBody>
      </MDBCard>
    </MDBContainer>
  );
}

export default App;
